<template>
<div class="tuandui page">
  <headers title="我的团队"></headers>
  <div class="scroll">
    <van-tabs v-model="active">
      <van-tab title="一级代理" name="1">
        <ul class="list" v-if="first_list.length>0">
          <li v-for="(item,index) in first_list" :key="index">
            <van-image class="avatar" width="38" height="38" :src="item.photo">
            </van-image>
            <div class="right">
              <div class="top flexSpaceBetween">
                  <div class="flexCenter">
                    <p class="username">{{item.username}}</p>
                    <img class="levelImg" :src="item.level_img" alt="">
                  </div>
                  <p class="money">资产：{{item.money}}</p>
              </div>
              <div class="bottom flexSpaceBetween">
                <p>手机号：{{item.account}}</p>
                <p>已赚佣金：{{item.sum_jj}}</p>
              </div>
            </div>
          </li>
        </ul>
        <van-empty v-else description="您暂时没有任何记录" :image="emptyImg"/>
      </van-tab>
      <van-tab title="二级代理" name="2">
        <ul class="list" v-if="tow_list.length>0">
          <li v-for="(item,index) in tow_list" :key="index">
            <van-image class="avatar" width="38" height="38" :src="item.photo">
            </van-image>
            <div class="right">
              <div class="top flexSpaceBetween">
                <div class="flexCenter">
                  <p class="username">{{item.username}}</p>
                  <img class="levelImg" :src="item.level_img" alt="">
                </div>
                <p class="money">资产：{{item.money}}</p>
              </div>
              <div class="bottom flexSpaceBetween">
                <p>手机号：{{item.account}}</p>
                <p>已赚佣金：{{item.sum_jj}}</p>
              </div>
            </div>
          </li>
        </ul>
        <van-empty v-else description="您暂时没有任何记录" :image="emptyImg"/>
      </van-tab>
      <van-tab title="三级代理" name="3">
        <ul class="list" v-if="three_list.length>0">
          <li v-for="(item,index) in three_list" :key="index">
            <van-image class="avatar" width="38" height="38" :src="item.photo">
            </van-image>
            <div class="right">
              <div class="top flexSpaceBetween">
                <div class="flexCenter">
                  <p class="username">{{item.username}}</p>
                  <img class="levelImg" :src="item.level_img" alt="">
                </div>
                <p class="money">资产：{{item.money}}</p>
              </div>
              <div class="bottom flexSpaceBetween">
                <p>手机号：{{item.account}}</p>
                <p>已赚佣金：{{item.sum_jj}}</p>
              </div>
            </div>
          </li>
        </ul>
        <van-empty v-else description="您暂时没有任何记录" :image="emptyImg"/>
      </van-tab>
    </van-tabs>
  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";

export default {
  name: "tuandui",
  components: {headers},
  data() {
    return {
      active: 1,
      emptyImg: require("/static/img/kong.png"),
      first_list: [{
        "userid": "3036",
        "username": "l*o",
        "photo": "/Public/home/wap/images/photo.png",
        "account": "185****5641",
        "money": "0.00",
        "level": "1",
        "sum_jj": 0
      }],    // 一级
      tow_list: [],    // 二级
      three_list: [],    // 三级
    }
  },
  mounted() {
    this.getTeamList()
  },
  methods: {
    getTeamList() {
      $api.teamdets({
        token: this.$store.getters['getToken'],
      }).then(res => {
          this.first_list = res.data.first_list;
          this.tow_list = res.data.tow_list;
          this.three_list = res.data.three_list;
      })
    },
  }
}
</script>

<style lang="scss">
.van-tabs__line {
  background: linear-gradient(0deg,#9eb0ff,#4969ff);
}
.tuandui {
  .scroll {
    .list {
      margin-top: 20px;
      li {
        margin: 0 15px;
        display: flex;
        align-items: center;
        height: 74px;
        border-bottom: 1px solid #f0f0f0;
        .avatar {
          flex-shrink: 0;
        }
        .right {
          padding-left: 10px;
          flex: 1;
          color: #999;
          font-size: 13px;
          .bottom {
            margin-top: 10px;
          }
          .username {
            font-size: 17px;
            margin-right: 5px;
            color: #000;
          }
          .levelImg {
            width: 61px;
            height: 19px;
          }
        }

      }
    }
  }
}
</style>